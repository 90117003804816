import Logo from '../Logo/Logo';
//import Mesh from '../Fiber/Mesh';

export default function Footer() {
	return(
	    <footer>
	      <div style={{ flexGrow:1 }} />
	      <div className="centered" >

	        <div style={{ margin:'0 0 30px 0' }} >
		      	<Logo backgroundColor="#212121" color="#fff" helperColor="#fff" textColor="#bbb" />
	        </div>

	        <span style={{ opacity:'0.5' }}>Alidad is managed by&nbsp;</span>
	        <a href="https://airtouch.fi" target="_blank" rel="noreferrer" style={{ color:'#f6f1eb' }} >
	          Airtouch Ltd
	        </a>
	        <br/><br/>
	        <a href={ process.env.PUBLIC_URL + '/documents/' + 'Airtouch_General_terms_and_conditions.pdf' } target="_blank" rel="noreferrer" style={{ color:'#f6f1eb' }} >
	          Terms and Conditions 
	        </a>
	        <span style={{ opacity:'0.5' }} >
	          &nbsp;&nbsp;|&nbsp;&nbsp; 
	        </span>
	        <a href={ process.env.PUBLIC_URL + '/documents/' + 'Alidad_Privacy_Policy.pdf' } target="_blank" rel="noreferrer" style={{ color:'#f6f1eb' }} >
	          Privacy Policy 
	        </a>
	        <br/><br/>
	        <span style={{ opacity:'0.5' }} >
	          © Airtouch Ltd 2022. All rights reserved.
	        </span>

	        <div>
	        <img style={{ padding:'30px 30px 30px 0', height:140 }} src={ process.env.PUBLIC_URL + '/img/eu1.png' } />
	        <img style={{ padding:'30px 30px 30px 0', height:140 }} src={ process.env.PUBLIC_URL + '/img/eu2.png' } />
	        </div>

	        <div style={{ height:'40px' }} />
	      </div>
	      <div style={{ flexGrow:1 }} />
	    </footer>
	)
}
