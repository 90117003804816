
export default function Content() {
	return(
		<>

			<h2 style={{ marginTop:'40px' }} >
				Transform your unique artworks into valuable online&#8209;assets
			</h2>
			<p>We process your collection of artworks into hyper-realistic 3D-models, viewable with a smartphone, tablet or a computer in a fast, secure and intuitive 3D-gallery, online.</p>
			<p>Our service is provided on premises, worldwide.</p>
			<p>Work is performed with the most sophisticated 3D scanners, resulting in sub-millimeter details and hyper-realistic reproduction of the original artwork on all screens.</p>
			<p>The process is secure, discrete and fully insured.</p>

			<h2 style={{ margin:'60px 0 30px 0' }} >
				Turnkey solution for galleries, collectors and artists
			</h2>
			<p>A gallery can share an exhibition online for patrons unable to attend in person, as well as promote all the works in the catalogue in a safe, private and compelling way.</p>
			<p>A collector can carry all favorite artworks everywhere, to share with friends and family, in person or remotely.</p>
			<p>An artist will be able to promote artwork remotely to galleries and prospective clients, in fine detail and from all angles.</p>

			<h2 style={{ margin:'60px 0 30px 0' }} >
				Add value and bring a new dimension to art
			</h2>
			<p>3D-models are optimized for delivery as media, NFT (non fungible tokens) or a secure online gallery.</p>
			<p>Our high performance servers quarantee safe and instant delivery, anytime and anywhere.</p>
			<p>Online galleries allow custom branding and can be attached to an existing web address of a gallery, an auction or other commercial entity.</p>
			<p>&nbsp;</p>
			<p>We are looking forward to provide you with a new dimension to art.</p>

		</>
	)
}
