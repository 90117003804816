import React, { useState } from 'react';
import Request from '../Request/Request';
import Input from './Input';
import Textarea from './Textarea';

export default function ContactForm() {

  const [ contact, setContact ] = useState( 0 );
  const [ formValues, setFormValues ] = useState( {
    name:'',
    company:'',
    email:'',
    phone:'',
    country:'',
    description:'',
  } );

  function handleChange( evt ) {
    const value = evt.target.value;
    setFormValues({
      ...formValues,
      [ evt.target.name ]: value
    });
  }

  function send() {
    //console.log( 'formValues', formValues )
    if ( formValues.name.length < 4 || formValues.email.length < 5 || formValues.phone.length < 5 ) {
      setContact( 2 )      
      return
    }
    setContact( 3 ) // submitting
    Request.post( 'posting-list-insert', 
      { 
        name:formValues.name, 
        company:formValues.company, 
        email:formValues.email, 
        phone:formValues.phone, 
        country:formValues.country, 
        description:formValues.description, 
      }, () => {
        setContact( 4 )      
      } 
    )
  }

  return (
    <>

      { ( contact === 0 ) &&
        <>
          <br/><br/>
          <button style={{ cursor:'pointer' }} onClick={ () => setContact( 1 ) } >Contact us</button>
          <br/><br/>
        </>
      }
    
      { ( contact === 1 || contact === 2 || contact === 3 ) && 
        <>
          <h2 style={{ margin:'60px 0 30px 0' }} >
            Contact us
          </h2>
          <p style={{ margin:'30px 0 30px 0' }} >Please enter your details below and we will be in touch.</p>
          <div style={{ fontSize:'18px', fontWeight:'700' }} >
          <Input id="name" title="Name" mandatory contact={ contact } values={ formValues } handleChange={ handleChange } />
          <Input id="company" title="Company" placeholder="Company or institution" contact={ contact } values={ formValues } handleChange={ handleChange } />
          <Input id="email" title="Email" mandatory contact={ contact } values={ formValues } handleChange={ handleChange } />
          <Input id="phone" title="Phone" mandatory contact={ contact } values={ formValues } handleChange={ handleChange } />
          <Input id="country" title="Country" contact={ contact } values={ formValues } handleChange={ handleChange } />
          <Textarea id="description" title="Description" rows="5" placeholder="e.g. sculptures, paintings, installations" contact={ contact } values={ formValues } handleChange={ handleChange } />
          <p style={{ fontSize:'16px', fontWeight:'600', margin:'30px 0', color:contact === 2 ? 'red' : '¤444' }} >Fields market with an asterisk (*) are mandatory</p>
          <button style={{ cursor:'pointer', marginRight:'20px', disabled:( contact === 3 ), opacity:contact === 3 ? 0.5 : 1 }} onClick={ () => send() } >Submit</button>
          <button style={{ cursor:'pointer', backgroundColor:'#7b8074' }} onClick={ () => setContact( 0 ) } >Cancel</button>
          <br/><br/>
          </div>
        </>
      }

      { ( contact === 4 ) && 
        <>
          <h2 style={{ margin:'60px 0 30px 0' }} >
            Thank you!
          </h2>
          <p>
            We will be in touch with you soon.
          </p>
          <br/><br/>
        </>
      }

    </>
  );
}

