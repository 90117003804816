//import ReactDOM from 'react-dom'
//import React, { useRef, useState } from 'react'
import { Canvas } from '@react-three/fiber'

//import Box from './Box'
import Icosahedron from './Icosahedron'

function Mesh( props ) {
  return (
    <Canvas 
      dpr={[ 1, 3 ]}
      style={{ width:'80px', height:'80px' }} 
    >
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Icosahedron color={ props.color } helperColor={ props.helperColor } position={[ 0, 0, 0 ]} />
      <Icosahedron color={ props.color } helperColor={ props.helperColor } position={[ 0.05, 0.05, 0 ]} />
      {/*
      <Icosahedron color={ props.color } helperColor={ props.helperColor } position={[ 0, 0.075, 0 ]} />
      <Icosahedron color={ props.color } helperColor={ props.helperColor } position={[ 0.075, 0, 0 ]} />
      */}
      <fogExp2 attach="fog" args={[ props.backgroundColor, 0.4 ]} />
    </Canvas>
  )
}

export default Mesh