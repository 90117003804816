import Mesh from '../Fiber/Mesh';

export default function Logo( props ) {
	return(
		<>
	        <div 
	        	style={{ 
	        		display:'inline-block', 
	        		width:'80px', height:'80px', 
	        		margin:'0 6px 0 0', 
	        		transform:'translateY(9px)' 
	        	}} 
        	>
				<Mesh backgroundColor={ props.backgroundColor } color={ props.color } helperColor={ props.helperColor } />
	        </div>
	        <div 
	        	className="bioRhyme" 
        		style={{ 
        			display:'inline-block', 
        			color:props.textColor, 
        			fontWeight:'500', fontSize:'30px', 
        			transform:'translateY(-22px)' 
        		}} 
    		>
				Alidad
	        </div>
		</>
	)
}
