//import ReactDOM from 'react-dom'
import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useHelper } from '@react-three/drei'
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper";

function Helper( props ) {
  useHelper( props.mesh, VertexNormalsHelper, 0.15, props.helperColor, 2 );
  return (false)
}

function Icosahedron( props ) {
  const mesh = useRef()
  useFrame((state, delta) => { 
    mesh.current.rotation.x -= 0.002 
    mesh.current.rotation.y -= 0.004
    //mesh.current.rotation.z += 0.002 
  } )
  return (
    <mesh
      {...props}
      ref={mesh}
      >
      <Helper mesh={ mesh } helperColor={ props.helperColor } />
      <icosahedronGeometry args={[ 3, 2 ]} />
      <meshStandardMaterial color={ props.color } wireframe wireframeLinecap="butt" wireframeLinewidth={ 2 } />
    </mesh>
  )
}

export default Icosahedron
